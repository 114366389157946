import React, { useRef, useEffect, useState } from "react";
import {Helmet} from "react-helmet";
import { Link, graphql } from "gatsby";
import { useScrollState } from "scrollmonitor-hooks";

import Layout from "../../components/layout";
import CardBlog from "../../components/CardBlog";

import ImageCover from "../../images/photos/cover-how-to-hire-ux-designer.png";
import ImageFirstScreen from "../../images/photos/0---Insiders-Guide-How-to-Find-Evaluate-and-Hire-UX-Designers.png";
import ImageSecondScreen from "../../images/photos/1---Where-Can-You-Find-UX-Designers---Targeted-Outreach.png";
import ImageCostHiring from "../../images/photos/cost-hiring-ux-designer.png";

// import ImageThirdScreen from "../../images/photos/2---Hire-UX-Designer-from-Online-UX-Design-Communities.png";
// import ImageFourthScreen from "../../images/photos/3---Find-User-Experience-Designer-from-UX-Job-Boards.png";
import ImageFifthScreen from "../../images/photos/4---How-Do-You-Evaluate-a-UX-Designer.png";
import ImageSixthScreen from "../../images/photos/5---What-Else-Should-You-Know-Before-Hiring-a-UX-Designer.png";
import ImageSeventhScreen from "../../images/photos/6---Interview-Questions-to-Ask-While-Hiring-a-UX-Designer.png";
import ImageEighthScreen from "../../images/photos/7 - interview questions for UX designer.png";

import ImageMistake1 from "../../images/photos/mistake-1.png";
import ImageMistake2 from "../../images/photos/mistake-2.png";
import ImageMistake3 from "../../images/photos/mistake-3.png";
import ImageMistake4 from "../../images/photos/mistake-4.png";
import ImageMistake5 from "../../images/photos/mistake-5.png";
import ImageMistake6 from "../../images/photos/mistake-6.png";

import ImageChapter1 from "../../images/photos/tmb-1.png";
import ImageChapter2 from "../../images/photos/tmb-2.png";
import ImageChapter3 from "../../images/photos/tmb-3.png";
import ImageChapter4 from "../../images/photos/tmb-42.png";
import ImageChapter5 from "../../images/photos/tmb-4.png";
import ImageChapter6 from "../../images/photos/tmb-5.png";
import TopUxAgency from "../../images/photos/top-ux-design-agency.png"
import UxServicesInfo from "../../images/photos/Freelance-vs-In-house-vs-Agency-matrix.png"
import UxAgencyChart from "../../images/photos/top-complex-experience-ux-agency.png"

const CurrentYear = new Date().getFullYear();

// import ImageLeanUx from "../../images/photos/main-pagep-lean-ux.png";
// import ImageArticle from "../../images/photos/article-9.jpg";
//
// import IconDifference from "../../images/icons/theme/design/difference.svg";
// import IconCrop from "../../images/icons/theme/design/crop.svg";
// import IconMagic from "../../images/icons/theme/design/magic.svg";
// import IconPenRuler from "../../images/icons/theme/design/pen-ruler.svg";
// import IconSocialTwitter from "../../images/icons/social/twitter.svg";
// import IconLike from "../../images/icons/theme/general/like.svg";
import IconPlus from "../../images/icons/theme/code/plus.svg";
import IconMinus from "../../images/icons/theme/code/minus.svg";
import IconClose from "../../images/icons/theme/navigation/close.svg";

import DecorationBlob1 from "../../images/decorations/deco-blob-1.svg";
import DecorationBlob18 from "../../images/decorations/deco-blob-18.svg";

import Divider1 from "../../images/dividers/divider-1.svg";

const HireUXDesignerPage = ({ data, location: { pathname } }) => {
  const ArticlesData = data.allDatoCmsArticle.edges;

  const [elemCtaOffsetTop, setElemCtaOffsetTop] = useState(0);
  // const [elemStickyOffsetTop, setElemStickyOffsetTop] = useState(0);

  useEffect(() => {
    document.addEventListener("click", toggleAccordion);

    return () => {
      document.removeEventListener("click", toggleAccordion);
    };
  });

  const toggleAccordion = (e) => {
    const panelID = e.target.getAttribute("aria-controls");

    if (!panelID) {
      return;
    }

    const panel = document.querySelector(`#${panelID}`);
    const panelAccordionContent = panel.querySelector(`.accordion-content`);
    const panelIcon = panel.querySelector(".icon");

    if (panelAccordionContent.className.includes("collapse")) {
      panelAccordionContent.classList.remove("collapse");
      panelIcon.classList.add("icon-rotate");
    } else {
      panelAccordionContent.classList.add("collapse");
      panelIcon.classList.remove("icon-rotate");
    }
  };

  return (
    <Layout headerBgClass="bg-white pillar-page-header" meta={{ seo: data.datoCmsPage.seo, path: pathname }}>
      <div className="pillar-page">
        <section className="o-hidden pb-2">
          <div className="decoration-wrapper">
            <div className="decoration left top" style={{ transform: "rotate(-62.34deg)" }}>
              <DecorationBlob1 className="bg-primary-alt" style={{ transform: "scale(5)" }} />
            </div>
          </div>

          <div className="container">
            <div className="row align-items-center min-vh-50">
              <div className="col-lg-6">
                <h1 className="display-4">
                  Hire a UX Designer: The {CurrentYear} Recruitment Guide
                </h1>
                <p className="lead">
                  Use this complete guide to hire a UX designer in {CurrentYear}.
                </p>
                <p className="lead">
                  Learn how to find, interview and recruit a designer with this step by step guide
                </p>
              </div>
              <div className="col-lg-6 d-flex align-items-center">
                <img
                  className="w-100"
                  src={ImageCover}
                  alt="Navigating the complexity of change aversion"
                />
              </div>
            </div>
          </div>

          <SectionOurBook />
        </section>

        <section className="section-chapter o-hidden mt-n6">
          <div className="decoration-wrapper">
            <div className="decoration right" style={{ top: -320, right: -70 }}>
              <DecorationBlob18 className="bg-primary-alt" style={{ transform: "scale(0.92)" }} />
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 text-center mb-6">
                <h2 className="display-4">Contents</h2>
              </div>
            </div>

            <div className="row pb-7 justify-content-center">
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#chapter-1">
                  <div className="card card--common card--blog--vertical card-body text-center">
                    <img src={ImageChapter1} alt="Chapter 1" />
                    <p className="lead mb-0 mt-1">Chapter 1</p>
                    <h4 className="mb-0">
                      With whom to work: Choose Wisely
                    </h4>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#chapter-2">
                  <div className="card card--common card--blog--vertical card-body text-center">
                    <img src={ImageChapter2} alt="Chapter 2" />
                    <p className="lead mb-0 mt-1">Chapter 2</p>
                    <h4 className="mb-0">Find a Designer</h4>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#chapter-3">
                  <div className="card card--common card--blog--vertical card-body text-center">
                    <img src={ImageChapter3} alt="Chapter 3" />
                    <p className="lead mb-0 mt-1">Chapter 3</p>
                    <h4 className="mb-0">How to Evaluate</h4>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#chapter-4">
                  <div className="card card--common card--blog--vertical card-body text-center">
                    <img src={ImageChapter4} alt="Chapter 4" />
                    <p className="lead mb-0 mt-1">Chapter 4</p>
                    <h4 className="mb-0">Cost of hiring</h4>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#chapter-5">
                  <div className="card card--common card--blog--vertical card-body text-center">
                    <img src={ImageChapter5} alt="Chapter 5" />
                    <p className="lead mb-0 mt-1">Chapter 5</p>
                    <h4 className="mb-0">6 Mistakes to Avoid</h4>
                  </div>
                </a>
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <a href="#chapter-6">
                  <div className="card card--common card-body text-center">
                    <img src={ImageChapter6} alt="Chapter 6" />
                    <p className="lead mb-0 mt-1">Chapter 6</p>
                    <h4 className="mb-0">Interview Questions</h4>
                  </div>
                </a>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-md-10 lead">
                <p className="mt-4 mb-5">
                  Anyone who has gone through the process of looking for, evaluating, and&nbsp;
                  <strong>hiring UX designers</strong>&nbsp;will tell you&nbsp;that it&rsquo;s not easy. 
                  First, you need to&nbsp;<strong>find a UX Designer</strong>, then you need to
                  evaluate them against structured criteria, and then finally, you need to convince
                  them to join your payroll. Of course, all that is easier said than done.
                </p>
                <p>
                  To make sure you get through this process as smoothly as possible, you’ll want all
                  the help you can get.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Start Phases */}
        <div>
          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-primary-alt"
            activePhase="Phases / Section 1"
            phaseId="chapter-1"
            bgDivider2="bg-white"
            headerContent={() => (
              <div className="container pb-2">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Chapter 1:</strong>
                    </p>
                    <h2 className="display-4">
                      Hire UX Designer: Types of UX Providers and How to Choose Wisely
                    </h2>
                  </div>
                  <div className="col-12">
                    <img
                      className="w-100"
                      src={ImageFirstScreen}
                      alt="Navigating the complexity of change aversion"
                    />
                  </div>
                </div>
              </div>
            )}
            mainContent={() => (
              <div>
                <div className="container pt-0">
                  <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-10">
                      <article className="article lead">
                        <h2 className="h1">Hiring a UX Designer Is Not a Piece of Cake Anymore</h2>
                        <p>
                          Since UX design plays such a vital role, businesses often find themselves
                          in a fix when it comes to&nbsp;<strong>hiring a UX designer.</strong>
                        </p>
                        <p>
                          This is because there are more than 900,000&nbsp;UX designers&nbsp;on the
                          LinkedIn network alone, which are either working as a freelancer, or as a
                          part of a UX agency, or are providing their services as an in-house UX
                          designer to businesses,
                        </p>
                        <p>
                          Choosing one between these three options is not an easy task. But we will
                          make it easy for you. Let&rsquo;s weigh each of these options based on
                          their pros and cons.
                        </p>
                      </article>
                    </div>
                  </div>
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <h2 className="h1">Different Ways to Get UX Services</h2>
                        <p>
                          Here’s a high-level matrix that outlines the contents of this chapter. 
                          In each subsection, we’ll go into more detail regarding the pros and cons of each engagement model.
                        </p>
                        <img
                          className="mb-6"
                          src={UxServicesInfo}
                          alt="Navigating the complexity of change aversion"
                        />

                      </div>
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                1
                              </div>
                              <h3 className="h2 mb-0">Hire a freelance UX designer</h3>
                            </li>
                          </ul>

                          <p>
                            When it comes to hiring a freelance UX designer,there is no dearth of
                            options. As of 2020, about 36% of the U.S. workforce was freelancing,
                            and this figure is expected to rise to 50% by the
                            <a href="https://www.cnbc.com/2020/09/15/a-snapshot-of-the-1point2-trillion-freelance-economy-in-the-us-in-2020.html#:~:text=Upwork's%20seventh%20annual%20study%20entitled,2%20million%20freelancers%20since%202019.">
                              &nbsp;next decade
                            </a>
                            .
                          </p>
                          <p>
                            Thus, hiring a freelance UX designer could prove to be an ideal option
                            if you are looking for skills that are not limited to a certain region.
                          </p>
                          <p className="mb-5">
                            Let&rsquo;s have a look at a few positive and negative aspects of hiring
                            a freelance UX designer.
                          </p>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Cost</h5>
                            </li>
                            <li>
                              <p>
                                When compared to full-time employees, hiring a freelance UX designer
                                or UX Strategist is definitely a cost-effective option. If you go by
                                an hourly rate, you only need to pay them for the number of hours that
                                they work.
                              </p>
                              <p>
                                The latter means that if an hour not worked is an hour not paid.
                                You only pay for the time the freelancer is actually working.
                                This is definitely a better option compared to hiring an agency or
                                full-time employee if the workload isn't consistent.
                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">
                                Pro: Easy-to-access remote skills
                              </h5>
                            </li>
                            <li>
                              <p>
                                Hiring a freelance UX designer doesn&rsquo;t limit you to a
                                <a href="https://adamfard.com/blog/remote-teams">
                                  &nbsp;certain area or region
                                </a>
                                . You could be running a startup in Australia and still be able to
                                hire a UX designer from India, or the other way around. This exposes
                                you to a wider pool of talent and ensures that you get nothing short
                                of the best.
                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Specialized skills</h5>
                            </li>
                            <li>
                              <p>You might already have an in-house designer, but you are now looking for a particular skill set, like data visualization or
                                <a href="https://adamfard.com/blog/ux-onboarding" target="_blank" rel="noopener noreferrer"> user onboarding</a>.
                                This is when a freelance UX designer can fill the gap and work with your in-house team to add value.
                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Deadline</h5>
                            </li>
                            <li>
                              <p>
                                If you have a time-sensitive project and you want a UX designer to
                                get started on it ASAP, a&nbsp;
                                <strong>freelance UI UX designer</strong>&nbsp;is your best bet.
                                This is because they tend to start working on short notice. The
                                quicker they get started, the more they earn! Secondly, since they
                                are experts in transitioning from one project to another and
                                toggling between various projects, they are good at delivering
                                quality work at the maximum speed.
                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconMinus className="icon icon-md align-items-left bg-danger" />
                              <h5 className="mb-0 text-danger">Con: Reliability</h5>
                            </li>
                            <li>
                              <p>
                                Although most freelancers are quick to get started and quick to
                                deliver, you can still have sour experiences working with
                                freelancers. They might go unresponsive, delay your work, and/or
                                submit substandard quality. The stakes aren't that high for them. One bad review will not undo a decent track record.
                                Additionally, you can't expect a freelancer to have access to the same equipment and other facilities than a 
                                <a href="https://adamfard.com/blog/top-product-design-agencies" target="_blank">product design agency</a>
                                 employee would have.
                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconMinus className="icon icon-md align-items-left bg-danger" />
                              <h5 className="mb-0 text-danger">Con: Communication</h5>
                            </li>
                            <li>
                              <p>
                                Since a freelancer might not necessarily be in your city,
                                communication could become tough, especially if they are in a
                                different time zone. Tools like Slack, Skype, Trello, Basecamp, and
                                the like could make things easier, but you would still need to
                                analyze things well. Additionally, freelancers, especially the ones overseas, are notorious for poor English.
                                Therefore, you should be on the lookout for a decent level of written and spoken English.
                              </p>
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                2
                              </div>
                              <h3 className="h2 mb-0 ">Hire a UX agency</h3>
                            </li>
                          </ul>
                          <p className="mb-5">
                            If you are ready to invest more of your budget into your company&rsquo;s
                            success, meaning the best quality work and utmost professionalism, you
                            should&nbsp;<strong>hire a UX agency</strong>. Let&rsquo;s see why (or
                            why not).
                          </p>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Professionalism</h5>
                            </li>
                            <li>
                              <p>
                                A&nbsp;
                                <a href="https://adamfard.com/about-us" target="_blank">UX UI design agency</a>
                                &nbsp;is professional. These agencies are often run by people who
                                themselves have years of experience as a UX designer. This makes them familiar with the industry inside and out.
                                There are very few problems these guys haven't dealt with.
                              </p>
                              <p>
                                Because top UX design agencies care about their reputation, they tend to be professional in their
                                approach. Unlike freelancers, agencies carry a heavier burden when it comes to receiving a bad review.

                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Access To a Diverse Range of Talents</h5>
                            </li>
                            <li>
                              <p>
                                Agencies are generally smart to have a range of talents diverse enough to execute all kinds of projects flawlessly.
                                In more practical terms, agencies usually have UX researchers, <a href="https://adamfard.com/blog/ux-architect-ux-analyst" target="_blank">UX architects</a>, interaction designers, etc.
                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Timeliness and Reliability</h5>
                            </li>
                            <li>
                              <p>
                                If you are looking for timely submissions with proper communication,
                                a UX design will fit the bill. Generally, a dedicated project
                                manager is assigned to you and takes care of your needs and serves
                                as your ultimate point of contact.
                              </p>
                              <p>
                                Therefore, you no longer have to fill your head with all the aspects
                                related to UX &mdash; the&nbsp;
                                <strong> design agency</strong>&nbsp;will take care of
                                everything.
                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Quality and Handling Complexity</h5>
                            </li>
                            <li>
                              <p>
                                The&nbsp;<strong>best UX design agencies</strong>&nbsp;are known for
                                delivering quality work. This is because each project goes through a
                                rigorous quality check before it is delivered to you. Furthermore,
                                a&nbsp;<strong>
                                <a href="https://adamfard.com/blog/top-ux-research-firm" target="_blank">UX research firm</a>
                                </strong>&nbsp;is backed by
                                experienced professionals who know how to meet clients&rsquo;
                                expectations flawlessly.
                              </p>
                              <p>
                                On top of that, among all other engagement types, agencies can handle the most complex projects due to access 
                                to a diverse range of designers and researchers. We’ve also created a chart to help you compare the 
                                reliability and complexity you should expect for each type of engagement.
                              </p>
                              <img
                                src={UxAgencyChart}
                                alt="Navigating the complexity of change aversion"
                              />

                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconMinus className="icon icon-md align-items-left bg-danger" />
                              <h5 className="mb-0 text-danger">Con: Cost</h5>
                            </li>
                            <li>
                              <p>
                                Although hiring an agency sounds like the best choice, be prepared
                                that they will cost you more. This is because most agencies follow a
                                systematic approach that includes research, planning, analysis, goal
                                setting, <a href="https:/ /adamfard.com/blog/design-validation" target="_blank">validation</a> with users and creation of developers handoff.
                              </p>
                              <p>
                                Learn more about our in-depth and data-driven <a href="https://adamfard.com/ux-design-process" target="_blank">UX process</a>
                              </p>
                              <p>
                                All this requires extensive knowledge, experience, and understanding
                                that a freelancer or an in-house designer might not have.&nbsp;
                              </p>
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                3
                              </div>
                              <h3 className="h2 mb-0 ">Hire an In-House UX Designer</h3>
                            </li>
                          </ul>
                          <p>
                            The third option that you could choose is the conventional path: hiring
                            an in-house UX designer. This option has its benefits but it also comes
                            with many hassles: From documentation to training and insurance, so much
                            needs to be taken care of.
                          </p>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Product Experience</h5>
                            </li>
                            <li>
                              <p>
                                When you&nbsp;<strong>hire a UI UX designer</strong>&nbsp;in-house,
                                they will only work on your product and application day-in and
                                day-out. This will make them intimately familiar with your product,
                                audience, and end users.
                              </p>
                              <p>
                                Although this is what a freelancer and agency can also achieve, if
                                an in-house designer is working at your setup, they are more likely
                                to get accustomed to your requirements.
                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Long-Term Value</h5>
                            </li>
                            <li>
                              <p>
                                Since designing UX is a long-term process, you need to make changes
                                and introduce upgrades quite frequently. Hiring an in-house designer
                                equips you with the long-term expertise that will work on your UX as
                                and when needed.
                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconMinus className="icon icon-md align-items-left bg-danger" />
                              <h5 className="mb-0 text-danger">Con: Limited Skills</h5>
                            </li>
                            <li>
                              <p>
                                One of the drawbacks of hiring an in-house UX designer is their
                                limited skills. They might be the best at user research, but landing
                                page creation is not in their wheelhouse. Alternatively, they might
                                be good at designing interactive design and <a href="https://adamfard.com/blog/prototyping" target="_blank">prototyping</a>, but not
                                doing content strategy.
                              </p>
                              <p>
                                In situations like these, you are left with a gap in your team,
                                which puts you in a tough spot.
                              </p>
                            </li>
                          </ul>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconMinus className="icon icon-md align-items-left bg-danger" />
                              <h5 className="mb-0 text-danger">Con: Cost</h5>
                            </li>
                            <li>
                              <p>
                                <strong>Hiring a UX designer</strong>&nbsp;is never easy on the
                                pocket. According to Glassdoor, the average salary of a UX designer
                                working at a company is $81,906. According to Payscale, it is
                                $72,504. Either way, this is a hefty sum, even if you opt for junior
                                to mid-level designers only.
                              </p>
                              <p>
                                Needless to say, these figures will go toward the higher end as the
                                designer&rsquo;s experience increases.
                              </p>
                              <p>
                                For a more extensive cost breakup,
                                <a href="https://www.huffpost.com/entry/the-real-cost-of-freelancers-vs-full-time-employees_b_58efd87de4b0156697224d86">
                                  &nbsp;read this post by HuffPost
                                </a>
                                .
                              </p>
                            </li>
                          </ul>
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconMinus className="icon icon-md align-items-left bg-danger" />
                              <h5 className="mb-0 text-danger">Con: Commitment</h5>
                            </li>
                            <li>
                              <p>
                                Hiring an in-house employee always entails a certain level of responsibility.
                                At the very least, employees rely on their companies as a stable source of income.
                                Therefore, you should only consider hiring a dedicated designer if you're sure there's a stable workload.
                              </p>
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                4
                              </div>
                              <h3 className="h2 mb-0 ">An Integrated Approach: The New Landscape of Work</h3>
                            </li>
                          </ul>
                          <p>
                            When it comes to product design, at Adam Fard Studio, we've developed our own approach that maximizes
                            the pros and minimizes the cons of the different ways to get UX services.
                            No longer teams are bound by the same geography or timezones.
                            Going remote has become a new normal for a plethora of companies, especially given COVID's impact.
                            The integrated approach is characterized as follows:
                          </p>

                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Leveraging Global Teams</h5>
                            </li>
                            <li>
                              <p>
                                The stability of an agency, without impact on the cost.
                                We pride ourselves on the fact that we've been remote since day one.
                                This allowed us to eliminate such expenses as rent, utilities, and other facilities.
                              </p>
                              <p>
                                The fact that we're remote also opens out an opportunity to hire anyone in the world.
                                That's why we never struggle with finding the right talent.
                                Additionally, a range of designers diverse enough allows for almost universal time-zone compatibility with our clients.
                              </p>
                            </li>
                          </ul>
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex align-items-center">
                              <IconPlus className="icon icon-md align-items-left bg-success" />
                              <h5 className="mb-0 text-success">Pro: Direct Communication</h5>
                            </li>
                            <li>
                              <p>
                                Another aspect of our approach is that we minimize the barrier between our designer and the client.
                                The talents we hire are competent enough in terms of business, design, and communication to have direct access to the client.
                                Therefore, there's no need for complex layers of middlemen.
                              </p>
                              <p>
                                <strong>Need to hire a design team?</strong><br/>
                                We can get a design project with world-class product designers in less than 72h: <a id="freeconsultation" href="https://adamfard.com/contact-us">Get in touch!</a>
                              </p>
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />

          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-primary-alt"
            activePhase="Phases / Section 2"
            phaseId="chapter-2"
            headerContent={() => (
              <div className="container pb-6">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Chapter 2:</strong>
                    </p>
                    <h2 className="display-4">Where Can You Find UX Designers?</h2>
                  </div>
                  <div className="col-12">
                    <img
                      src={ImageSecondScreen}
                      alt="Navigating the complexity of change aversion"
                    />
                  </div>
                </div>
              </div>
            )}
            mainContent={() => (
              <div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h2 className="h1">Hire a UX Agency</h2>
                            <p>
                              When it comes to searching for a right agency, there are multiple ways 
                              you can find the one that fits your needs. The first thing we would suggest 
                              is doing a google search. It is likely, that you will find websites like&nbsp;  
                              <a href="https://clutch.co/de/agencies/ui-ux/leaders-matrix" target="_blank" rel="nofollow"> 
                                Clutch
                              </a>,
                              GoodFirms or&nbsp;
                              <a href="https://themanifest.com/de/user-experience/agencies" target="_blank" rel="nofollow"> 
                                Manifest
                              </a>. 
                            </p>
                            <p>
                              Agency-ranking websites like these are a good way 
                              to see what UX agencies specialize in and what reviews they have.
                            </p>

                            <p> 
                              Here’s how these rankings look:
                            </p>
                            <a href="https://clutch.co/de/agencies/ui-ux/leaders-matrix" target="_blank" rel="nofollow">
                              <img src={TopUxAgency} alt="Chapter 4" />
                            </a>
                            <p className="mt-4">
                              And here’s how the reviews look:
                            </p>

                            
                            <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script> 
                            <div class="clutch-widget" data-nofollow="true" data-url="https://widget.clutch.co" data-widget-type="12" data-height="375" data-clutchcompany-id="975724">
                            </div>
                            

                            <p>
                              Then, you should identify agencies that fit your budget and specialize in the 
                              things you need designed. Having done that, we would suggest that you do additional 
                              research into these companies’ digital footprints. While agency-ranking websites 
                              are for the most part reliable, they only tell you half of the story.
                            </p>
                          
                        </article>
                      </div>
                    </div>
                  </div>
                  <SectionCaseStudies />

                  <hr className="my-0" />
                </div>



                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h2 className="h1">Hire an in-house UX designer</h2>
                          <h3 className="h2">Targeted Outreach</h3>
                          <p>
                            Targeted outreach is one of the most effective ways to find a great UX
                            designer, especially if you have a team that has worked with &mdash; or
                            knows someone who has worked with &mdash; designers in the past. As the
                            name suggests, targeted outreach means you need to make the first move
                            and contact prospective designers.
                          </p>
                          <p>
                          In {CurrentYear}, the primary source for this kind of outreach is LinkedIn. 
                          You can easily target the UX designer demographic in a certain area and contact them using 
                          LinkedIn <a href="https://dripify.io/" target="_blank">automation software</a> like Dripify. Additionally, you can ask for referrals from both your own 
                          team and the designers you're contacting..
                          </p>


                          <h3 className="h2">Online UX Design Communities</h3>
                          <p>
                            There are plenty of online communities where UX designers can show off
                            their designs and share portfolios. Here, you can find skilled UX
                            designers for your startups from all around the world, browse endless
                            designs, and even post a job with your specific requirements.
                          </p>
                          <p>
                            Keep in mind that while these online communities are an awesome place to
                            screen visual designs, they&rsquo;re not particularly useful when it
                            comes to understanding the strategy, vision, interaction, or
                            architecture used by the designers to build the final product. Namely,
                            you get a glimpse of the surface but not what is beneath it.
                          </p>
                          <blockquote>
                            A beautiful product that doesn’t work very well is ugly.
                            <br /> — Jonathan Ive
                          </blockquote>
                          <p>
                            For this reason, it&rsquo;s important that you know exactly what you
                            want and need from your UX designer. Otherwise, you might hire a UX
                            designer who excels in creativity but is lacking in the strategic
                            department.
                          </p>
                          <p>
                            Prominent online UX and digital design communities include Dribbble,
                            Behance, Coroflot, and Awwwards. Additionally, a simple search in LinkedIn and Facebook groups will help you find a good number of job-seekers.
                          </p>



                          <h3 className="h2">UX Job Boards</h3>
                          <p>
                            Even though you can post your UX design job post on any job search
                            engine, it&rsquo;s best to post on job boards that specialize in UX
                            design. This way, you can be certain that your post will target and
                            reach the right audience: UX designers.
                          </p>
                          <p>Here are a few job boards for <a href="https://uiuxdesignerjobs.com/" target="_blank">UI UX Designer Jobs</a> where you can find your ideal designer:</p>
                          <ul>
                            <li>
                              <a
                                href="https://www.uxjobsboard.com/?ref=95"
                                target="_blank"
                                rel="noopener"
                              >
                                <strong>UX Jobs Board</strong>
                              </a>
                            </li>
                            <li>
                              <strong>Just UX Job</strong>
                            </li>
                            <li>
                              <a
                                href="https://www.designerhangout.co/"
                                target="_blank"
                                rel="noopener"
                              >
                                <strong>Designer Hangout</strong>
                              </a>
                            </li>
                            <li>
                              <strong>
                                <a href="https://authenticjobs.com/" target="_blank" rel="noopener">
                                  Authentic Jobs
                                </a>
                              </strong>
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h2 className="h1">Hire a freelance UX designer</h2>
                          <p>
                            Among all of the types of design help, freelancers are the easiest to hire. 
                            Unlike, agencies or in-house designers, freelancers normally don’t require things like contracts, 
                            extensive on boarding, team integration, etc. This, of course, comes at the cost of expertise and reliability.
                          </p>
                          <p>
                            The freelancer platforms that are the most popular right not are:
                          </p>
                          <ul>
                            <li>
                              <a
                                href="https://www.toptal.com/#enlist-nothing-but-skillful-app-designers"
                                target="_blank"
                                rel="noopener nofollow"
                              >
                                <strong>Toptal* </strong>
                              </a>
                              features the most experienced mid-to-senior level freelancers, most of which would prefer mid-term project. As such, it might be one of the most expensive options.
                              <p style={{fontSize:"16px",marginTop:"12px"}}>*designer referral bonus: you will earn $500 after successful trial period</p>
                            </li>
                            <li>
                              <a
                                href="https://www.upwork.com"
                                target="_blank"
                                rel="noopener nofollow"
                              >
                              <strong>Upwork </strong>
                              </a>
                              offers a good range of expertise and prices when it comes to freelancers. 
                              You can find design help for both short- and long-term projects there.
                            </li>
                            <li>
                              <a
                                href="https://www.fiverr.com/"
                                rel="noopener nofollow"
                              >
                              <strong>Fiverr </strong>
                              </a>
                              is the kind of platform that works best for short-term projects, 
                              such as creating an illustration or editing a video.
                            </li>
                          </ul>
                          <p>
                            One thing you should note: one way or another all of these platforms charge commission, with Toptal being the highest. 
                          </p>
                          <p>
                            As such, if you’re tight on budget, you can try to leverage your own network to establish a 
                            direct cooperation with a freelancers without the need for a middleman.
                          </p>
                          
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />

          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-primary-alt"
            activePhase="Phases / Section 3"
            phaseId="chapter-3"
            headerContent={() => (
              <div className="container pb-2">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Chapter 3:</strong>
                    </p>
                    <h2 className="display-4">How Do You Evaluate a UX Designer?</h2>
                  </div>

                  <div className="col-12">
                    <img
                      src={ImageFifthScreen}
                      alt="Navigating the complexity of change aversion"
                    />
                  </div>
                </div>
              </div>
            )}
            mainContent={() => (
              <div>
                <div className="container pt-0">
                  <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-10">
                      <article className="article lead">
                        <p>
                          When you find a UX designer who seems like a good fit, it’s time to put
                          them to the test and evaluate them. How do you do this? Everyone has their
                          own methods, but most interviewers just go with their gut feeling. This is
                          wrong. Your instincts — or innate biases — should not be the main
                          selection criterion for a UX designer.
                        </p>
                        <p>
                          Instead, you should develop valid selection criteria to make the
                          evaluation — and ultimately, the selection — more accurate.{" "}
                        </p>
                        <p>
                          To objectively evaluate UX designers you want to hire, follow these four
                          steps:
                        </p>
                        <ol>
                          <li>
                            <strong>Know what you want.</strong>&nbsp;Define the specs of your
                            project and design requirements.
                          </li>
                          <li>
                            <strong>Evaluate hard skills.</strong>&nbsp;What skills are vital to the
                            successful completion of the project?
                          </li>
                          <li>
                            <strong>Explore soft skills.</strong>&nbsp;What personal attributes does
                            the designer possess?
                          </li>
                          <li>
                            <strong>Test UX designers.</strong>&nbsp;Can they create a viable design
                            solution that satisfies expectations?
                          </li>
                        </ol>
                        <p>
                          Additionally, you want your criteria to feature various qualities and
                          skills that are essential to your project. This will ensure that the UX
                          designer can easily adjust and contribute to your team.{" "}
                        </p>
                        <p>Here’s what to look for when hiring a UX designer:</p>
                      </article>
                    </div>
                  </div>
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h2 className="h1">Design Skills</h2>
                          <p>
                            <strong>What do you want to find out?</strong>&nbsp;Can this UX designer
                            do the job efficiently?
                          </p>
                          <p>
                            <strong>How can you find out?</strong>&nbsp;Review portfolio, ask
                            questions, and give design exercises.
                          </p>
                          <ul>
                            <li>
                              <strong>Experience.&nbsp;</strong>Do they have sufficient experience
                              in UX design?
                            </li>
                            <li>
                              <strong>Problem setting.</strong>&nbsp;Can they identify, question,
                              and prioritize problems?
                            </li>
                            <li>
                              <strong>User-centered process.</strong>&nbsp;Do they base their design
                              decisions on user insights?
                            </li>
                            <li>
                              <strong>Idea generation.</strong>&nbsp;Are they able to quickly
                              generate high-quality solutions?
                            </li>
                            <li>
                              <strong>Systems thinking.</strong>&nbsp;Do they understand how their
                              solution will fit into users&rsquo; lives?
                            </li>
                            <li>
                              <strong>Visual appeal.</strong>&nbsp;Is their design appropriate for
                              the audience?
                            </li>
                            <li>
                              <strong>Innovation.</strong>&nbsp;Does their design feel new and
                              original?
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h2 className="h1">People Skills</h2>
                          <p>
                            <strong>What do you want to find out?</strong>&nbsp;Do I want to work
                            with this designer?
                          </p>
                          <p>
                            <strong>How can you find out?</strong>&nbsp;One-on-one interviews, cover
                            letters, and back-and-forth emails.
                          </p>
                          <ul>
                            <li>
                              <strong>Communication.</strong>&nbsp;Are they a good listener and a
                              persuasive speaker?
                            </li>
                            <li>
                              <strong>Collaboration.</strong>&nbsp;Can they work efficiently as part
                              of a team?
                            </li>
                            <li>
                              <strong>Cultural contribution.</strong>&nbsp;Do they represent <a href="https://adamfard.com/blog/value-centered-design" target="_blank">your
                              company&rsquo;s values</a>?
                            </li>
                            <li>
                              <strong>Leadership.</strong>&nbsp;Do they take proud ownership of
                              their work and decisions
                            </li>
                            <li>
                              <strong>Mission.</strong>&nbsp;Did they read up on your company prior
                              to the interview?
                            </li>
                          </ul>
                          <p>
                            UX designers that&nbsp;
                            <a
                              href="https://www.huffpost.com/entry/hiring-hacks-6-tips-to-hire-ui-designers_b_5977aa49e4b01cf1c4bb73ee"
                              target="_blank"
                              rel="noopener"
                            >
                              possess these skills
                            </a>
                            &nbsp;are definitely worth considering for the job.&nbsp;
                          </p>
                          <p>
                            If you&rsquo;re stuck on finding the right questions to ask UX designers
                            during interviews, here are a few that will give you the information you
                            need to make the final decision:
                          </p>
                          <ul>
                            <li>Describe your design process and methods.</li>
                            <li>Describe the challenges you faced on a recent project.</li>
                            <li>How did you approach the problem?</li>
                            <li>
                              Provide examples of how you deal with user research and usability
                              testing.
                            </li>
                            <li>How do you handle criticism from clients?</li>
                            <li>What does it mean to be a great UX designer?</li>
                            <li>What analytical tools do you use to evaluate your designs?</li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>


                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h2 className="h1">Hiring a designer: Red Flags</h2>
                          <p className="">
                            <strong>🚩 Giving an estimate without fully understanding the scope</strong>
                          </p>
                          <p>
                            So you’ve found a seemingly good candidate. You’re ready to get started as soon as possible. 
                            However, before you do, you need to have an idea of how much things are going to cost. 
                          </p>
                          <p>
                            Any designer knows that this isn’t an easy question to answer. In order to do that, you need a clear image of what’s 
                            been done and what needs to be done. On top of that, design is an iterative process.
                          </p>
                          <p>
                            There’s no way to tell in advance how many iterations this or that project is going to require.
                          </p>
                          <p> 
                            If a candidate gives you a time/budget estimate without diving deep into your business and product needs, 
                            beware. What you’re getting into is likely a designer who designs just for the sake of it. That’s a recipe for mediocrity, 
                            that you will eventually have to redo.   
                          </p>
                          <p className="mt-5">
                            <strong>🚩 Being a jack of all trades</strong>
                          </p>
                          <p>
                            Sometimes, it is very tempting to look for a designer who can do it all: research, 
                            stunning UI, motion design, video editing, you name it. True, you may stumble upon a 
                            professional who is genuinely skilled in all of these things. 
                          </p>
                          <p>
                            However, more often than not, 
                            the breadth of  skills are balanced by the lack of expertise in either of them. 
                          </p>
                          <p>
                            Conversely, you should be wary of candidates who claim to be an expert in all subfields within design. 
                            Again, for a specific task, specialists  are more likely to do a great job than a generalist.
                          </p>
                          <p>
                            Lastly, we would add that a good solution for a project that requires a variety of skills is agencies. 
                            Most design companies have a wide range or design talent that’s capable of multiple skills.
                          </p>
                            
                          <p className="mt-5">
                            <strong>🚩 Skipping discovery phase</strong>
                          </p>
                          <p>
                            If a designer you’ve hired starts producing UI immediately, beware. 
                            Design for the sake of design is a horrible idea. Design, just like marketing, sales, product management, 
                            and other fields is there to serve the business. 
                          </p>
                          <p>
                            How can you produce user interface elements 
                            without understanding the larger picture and business goals? You can’t.
                          </p>

                          <p className="mt-5">
                            <strong>🚩 Starting from scratch</strong>
                          </p>
                          <p>
                            Every once in a while your product needs a makeover. There’s nothing negative about it. 
                            Facebook, for instance, has had more redesigns since its launch than we care to count. 
                          </p>
                          <p>
                            However, you don’t always need to start from scratch. Quite to the contrary, more often than not, 
                            gradual changes are more productive.
                          </p>

                          <p>
                            As such, few honest designers will insist upon scrapping everything you’ve designed without 
                            a close and thorough observation. 
                            There aren’t many products designed so badly that there isn’t anything worth saving.
                          </p>

                          
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

              </div>
            )}
          />

          
<WatchedPhaseSection
            isDivider={true}
            bgColor="bg-primary-alt"
            activePhase="Phases / Section 4"
            phaseId="chapter-4"
            headerContent={() => (
              <div className="container pb-6">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Chapter 4:</strong>
                    </p>
                    <h2 className="display-4">How much you should expect to pay a designer?</h2>
                  </div>
                  <div className="col-12">
                    <img
                      src={ImageCostHiring}
                      alt="Navigating the complexity of change aversion"
                    />
                  </div>
                </div>
              </div>
            )}
            mainContent={() => (
              <div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <p>
                            There’s isn’t a straightforward answer to this. The factors at play include the geography, 
                            specific area of expertise, and the type of engagement you want to have. 
                            However, we’ll try to give you some data to get you an idea of the price ranges you’re looking at.
                          </p>
                          <h2 className="h1">Freelancers</h2>
                          <p>
                            Freelancers might charge from $10 to $100 per hour or more. 
                            According to Upwork, the median UX Designers charge about $25–$39/hr. on their platform. 
                            However, you can find designers with all sorts of hourly rates there.
                          </p>
                          <p>
                            Another freelance platform Toptal doesn’t publicly share their designers’ rates, 
                            but you would definitely expect them to be more expensive than Upwork. 
                            We would estimate the hourly rates to reach $120 per hour and above.
                          </p>
                          <p>
                            Fiverr, among the other platforms, might offer the cheapest per-project prices out there.
                          </p>

                          <h2 className="h1">In-house designers</h2>
                          <p>
                            For an in-house designer, salaries might range from $50 to $175 k. a year, in the US. 
                            According to a survey conducted by abdz.do, Switzerland has highest average salary for UX designers 
                            ($102,614 per year), while Morocco the lowest ($1,006 per year).
                          </p>

                          <h2 className="h1">Agencies</h2>
                          <p>
                            Just like with freelancers and in-house designers, it depends on a great number of things. 
                          </p>
                          <p>
                            In Western countries, agencies typically charge for a month of work ($8000 per full-time junior designer and above; 
                            $10.000-$16.000 – for a senior one) or an hourly rate (typically $50 and above). 
                            However, prices range drastically across the globe.
                          </p>

                          
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

              </div>
            )}
          />
          
          
          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-primary-alt"
            activePhase="Phases / Section 5"
            phaseId="chapter-5"
            headerContent={() => (
              <div className="container pb-6">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Chapter 5:</strong>
                    </p>
                    <h2 className="display-4">6 Mistakes to avoid when hiring a Designer</h2>
                  </div>
                  <div className="col-12">
                    <img
                      src={ImageSixthScreen}
                      alt="Navigating the complexity of change aversion"
                    />
                  </div>
                </div>
              </div>
            )}
            mainContent={() => (
              <div>
                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                1
                              </div>
                              <h2 className="h1 mb-0 ">Merging Several Roles</h2>
                            </li>
                          </ul>
                          <img
                            src={ImageMistake1}
                            alt="Navigating the complexity of change aversion"
                          />
                          <p>
                            A common mistake that many companies make is merging several job roles
                            into one. Though it can be super tempting to hire one person to handle a
                            wide range of tasks, like UX, UI, development, marketing, and QA,
                            onboarding a technical &ldquo;rockstar&rdquo; can be counter-productive.
                            In fact, it can even be detrimental to your product.
                          </p>
                          <p>
                            Sure, you can get lucky and strike gold with a multi-talented designer
                            who can juggle several roles. However, most of the time, you&rsquo;ll
                            end up with an amateur, namely, someone who only dabbles in UX design.
                          </p>
                          <p>
                            Avoid hiring a second-rate UX designer, and opt to hire a bona fide
                            expert instead. This move will save you a great deal of time and money
                            in the long run.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                2
                              </div>
                              <h2 className="h1 mb-0 ">Prioritizing Specific Skills</h2>
                            </li>
                          </ul>
                          <img
                            src={ImageMistake2}
                            alt="Navigating the complexity of change aversion"
                          />
                          <p>
                            Constant changes in technology means the demand for specific skills are
                            also changing. This is why hiring a UX designer based on a single skill
                            can be dangerous.
                          </p>
                          <p>So, avoid it.</p>
                          <blockquote>
                            <p>
                              Hiring should always be part of your long-term strategy, not a quick
                              fix to an immediate problem.
                            </p>
                            <p>― Steven J. Bowen</p>
                          </blockquote>
                          <p>
                            You need to keep an open mind and take a more holistic approach when
                            hiring. For instance, rather than base your hiring decision on a
                            specific skill set, assess the designer&rsquo;s critical thinking.
                          </p>
                          <p>
                            How do they go about solving complex problems? Are they able to
                            translate intricate business requirements into great UX design? What
                            about their design process?
                          </p>
                          <p>
                            These are the skills you should be assessing when hiring a UX designer.
                            Critical thinking, intuition, and problem-solving skills never go out of
                            fashion. This, you can be sure of.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                3
                              </div>
                              <h2 className="h1 mb-0 ">Overestimating a UX Portfolio</h2>
                            </li>
                          </ul>
                          <img
                            src={ImageMistake3}
                            alt="Navigating the complexity of change aversion"
                          />
                          <p>
                            When going through a UX designer&rsquo;s portfolio, it&rsquo;s important
                            that you know what to look for. A frequent mistake that many companies
                            and recruiters make is judging a UX portfolio on quantity, specifically,
                            the number of <a href="https://adamfard.com/ux-projects" target="_blank">UX case studies</a> and samples that the designer has included.
                          </p>
                          <p>
                            This is a costly mistake when hiring a UX designer. Why? The number of
                            samples has nothing to do with the UX design process nor does it
                            accurately reflect the designer&rsquo;s skills and experience.
                          </p>
                          <p>
                            Don&rsquo;t assume a UX designer with 3 to 5 work samples in their
                            portfolio is any less experienced or qualified than someone with 10 to
                            20 samples.
                          </p>
                          <p>Because they&rsquo;re not.</p>
                          <p>
                            A great UX designer is aware that several case studies are more than
                            enough to showcase their skills and experience.
                          </p>
                          <p>
                            Ditch the assumptions and focus on what&rsquo;s inside the portfolio. A
                            UX portfolio should be presented in a way that clearly shows how and why
                            the designer contributed to the project and the end result. Here&rsquo;s
                            what you should be judging when looking at a UX portfolio:
                          </p>
                          <ul>
                            <li>
                              <strong>Clearly defined problems</strong>
                            </li>
                            <li>
                              <strong>Initial user research findings</strong>
                            </li>
                            <li>
                              <strong><a href="https://adamfard.com/blog/wireframes" target="_blank">Wireframes</a></strong>
                            </li>
                            <li>
                              <strong>User testing insights</strong>
                            </li>
                            <li>
                              <strong>Final designs and solutions</strong>
                            </li>
                            <li>
                              <strong>Skills used in the project</strong>
                            </li>
                            <li>
                              <strong><a href="https://adamfard.com/blog/ux-metrics" target="_blank">Key metrics</a></strong>
                            </li>
                          </ul>
                          <p>
                            When screening a UX portfolio, put an emphasis on quality and depth, not
                            quantity.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                4
                              </div>
                              <h2 className="h1 mb-0 ">Expecting a Quick Turnaround Time</h2>
                            </li>
                          </ul>
                          <img
                            src={ImageMistake4}
                            alt="Navigating the complexity of change aversion"
                          />
                          <p>
                            Just because a UX designer has 10 years of experience doesn&rsquo;t mean
                            that they can magically finish a project overnight. It&rsquo;s not going
                            to happen and you shouldn&rsquo;t expect it to.
                          </p>
                          <p>
                            The whole point of a great UX design is to improve user experience and
                            amplify conversions. Unless you want to sabotage your own product, then
                            rushing a project and the UX designer isn&rsquo;t going to do you any
                            good. You must allow enough time for the designer to perform their job
                            accordingly.
                          </p>
                          <p>
                            Speak to the designer about expected deadlines to avoid any
                            misunderstandings and disappointment later on.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                5
                              </div>
                              <h2 className="h1 mb-0 ">Posting a Boring Job Ad</h2>
                            </li>
                          </ul>
                          <img
                            src={ImageMistake5}
                            alt="Navigating the complexity of change aversion"
                          />
                          <p>
                            In order to attract top talent, you need to create a job description that will
                            stand out from the crowd. Don&rsquo;t use a stock template you copied
                            from somewhere on the internet. Take your time to create a job post that
                            will give designers a glimpse into your company. Share your company
                            story, promote your work culture, and present your vision.
                          </p>
                          <p>
                            If you&rsquo;re at that stage of getting your business off the ground
                            and can&rsquo;t offer a huge salary, don&rsquo;t stress. High salaries
                            don&rsquo;t always guarantee finding a great UX designer.
                          </p>
                          <blockquote>
                            <p>
                              When people are financially invested, they want a return. When people
                              are emotionally invested, they want to contribute.
                            </p>
                            <p>&mdash; Simon Sinek</p>
                          </blockquote>
                          <p>
                            Focus on other ways to spark interest. Offer career growth, learning
                            opportunities, and a stable work-life balance. Explain how your
                            opportunity is unique, and suggest the ways designers can make an
                            impact. Making a good first impression is a crucial part of the hiring
                            process, so make yours count.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                6
                              </div>
                              <h2 className="h1 mb-0 ">
                                Ignoring the Fiercely Competitive UX Market
                              </h2>
                            </li>
                          </ul>
                          <img
                            src={ImageMistake6}
                            alt="Navigating the complexity of change aversion"
                          />
                          <p>
                            UX is an&nbsp;<em>extremely</em>&nbsp;competitive market. Companies,
                            agencies, and consultancies are all vying for the attention of UX
                            designers. For this reason, it&rsquo;s important that you learn <a href="https://adamfard.com/blog/make-designers-love-you" target="_blank">how to
                            retain talent</a>&nbsp;<em>&mdash;</em>&nbsp;even before you acquire it.
                            Here are effective ways to motivate and retain top UX design talent:
                          </p>
                          <ul>
                            <li>
                              <strong>Opportunity.</strong>&nbsp;Let the UX designer work on
                              projects they are passionate about.
                            </li>
                            <li>
                              <strong>Self-actualization.</strong>&nbsp;Help them achieve their
                              personal goals and ambitions.
                            </li>
                            <li>
                              <strong>Autonomy.</strong>&nbsp;Encourage them to share ideas and take
                              initiative.
                            </li>
                            <li>
                              <strong>Professional development.</strong>&nbsp;Offer training and
                              benefit packages that inspire growth.
                            </li>
                            <li>
                              <strong>Breathing space.</strong>&nbsp;Ensure a positive and
                              stress-free working environment.
                            </li>
                          </ul>
                          <p>
                            Whatever you do, do not underestimate your competitors when it comes to
                            hiring a UX designer. The competitive market makes it difficult to find
                            and then retain UX designers. Fine-tune your employee retention strategy
                            prior to hiring.
                          </p>
                          <p>
                            So now that you have done your homework, it is the time to fix an
                            interview with your shortlisted candidates.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />

          <WatchedPhaseSection
            isDivider={true}
            bgColor="bg-primary-alt"
            activePhase="Phases / Section 6"
            phaseId="chapter-6"
            headerContent={() => (
              <div className="container pb-2">
                <div className="row justify-content-center">
                  <div className="col-12 col-md-8 d-flex flex-column text-center">
                    <p>
                      <strong>Chapter 6:</strong>
                    </p>
                    <h2 className="display-4">
                      Hiring: Interview Questions to Ask While Hiring a UI UX Designer
                    </h2>
                  </div>

                  <div className="col-12">
                    <img
                      src={ImageSeventhScreen}
                      alt="Navigating the complexity of change aversion"
                    />
                  </div>
                </div>
              </div>
            )}
            mainContent={() => (
              <div>
                <div className="container pt-0">
                  <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-8 col-md-10">
                      <article className="article lead">
                        <p>
                          Undoubtedly, when you are out in the market to&nbsp;
                          <strong>hire a UX designer</strong>&nbsp;for your firm, start-up, venture
                          or business, you are just as nervous and pressurized as the candidate in
                          front of you. This is simply because you don&rsquo;t want to make any
                          mistake and lose a gem of a UX designer.
                        </p>
                        <p>
                          No worries. Here are a few&nbsp;
                          <strong>interview questions for UX designers</strong>&nbsp;that you must
                          ask your candidate.
                        </p>
                      </article>
                    </div>
                  </div>
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                1
                              </div>
                              <h2 className="mb-0 ">
                                Did you solve any problem area that was invisible but enhanced the
                                overall user experience of the application?
                              </h2>
                            </li>
                            <li>
                              <p>
                                Your candidate could provide a range of answers here. And believe
                                us, there is no right or wrong. However, to judge your candidate,
                                look for any indication in their answer that demonstrates their
                                skills to handle complexity in a product. This will prove to be a
                                testimony of their understanding of what UX is composed of.
                              </p>
                              <p>
                                Some possible answers could be removing a redundant step from the
                                process, reducing the amount of data inputted, or developing a
                                data-rich dashboard.
                              </p>
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                2
                              </div>
                              <h2 className="mb-0 ">
                                How do you understand the vision of the product and display it
                                appropriately on the screen?
                              </h2>
                            </li>
                            <li>
                              <p>
                                Needless to say, there are a lot of appropriate answers to this
                                question. To understand the feasibility of this answer properly, it
                                is important that you understand your vision properly.
                              </p>
                              <p>
                                Analyze how the answer of your candidate aligns with your overall
                                vision. Although they won&rsquo;t necessarily be the same, they
                                should resemble a large extent.
                              </p>
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                3
                              </div>
                              <h2 className="mb-0 ">
                                What do you consider an ideal working environment?
                              </h2>
                            </li>
                            <li>
                              <p>
                                This an important question both for you and your candidate. It is
                                essential that both the parties involved work in harmony with each
                                other, and prove to be a great fit.
                              </p>
                              <p>
                                For instance, if your candidate answers the questions with,
                                &ldquo;working in a team with vibrant and dynamic atmosphere&rdquo;,
                                and your company is a bunch of tech-geeks with everyone focusing on
                                their tasks and no one speaking to each other, you can sense the
                                incompatibility out there.
                              </p>
                              <p>
                                Similarly, if you prefer your
                                <a href="https://adamfard.com/blog/remote-teams">
                                  &nbsp;employees to work remotely
                                </a>
                                , and if this is not something that doesn&rsquo;t go well with your
                                candidate, you might need to reconsider.
                                <br />
                                <br />
                                Other important factors that you must bring into consideration
                                include &ndash; how joyful will they be when working with you, do
                                you have projects that are the right fit for their skills and
                                interests, is the person in question trustworthy, and do their
                                mission and vision statement aligns with the mission and vision of
                                your company?
                              </p>
                              <p>
                                Are they comfortable with the business goals of your company and
                                will leave no stone unturned in achieving that goal?
                              </p>
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                4
                              </div>
                              <h2 className="mb-0 ">What UX tools do you commonly use?</h2>
                            </li>
                            <li>
                              <p>
                                This question is asked just for informational purposes and you
                                should not base your decision solely on the answer to this question.
                                Although tools play an important role, the tool match is not that
                                important.
                              </p>
                              <p>
                                If your candidate knows one tool and not another, the chances are
                                high that they will easily be able to pick up the different tools
                                too. However, there are a few exceptions here.
                              </p>
                              <p>
                                If you are&nbsp;hiring a UI/UX designer&nbsp;for an urgent project
                                and want them to work on a tool right from day one, do not expect a
                                quick turnaround time here. Your designer will take their own time,
                                depending on the learning curve of the tool, and their grasping
                                power.
                              </p>
                              <p>
                                Thus, the know-how of your designer on the <a href="https://adamfard.com/blog/ux-tools" target="_blank">UX tools</a> should not be a
                                subject of concern if you are hiring them for long term product
                                development.
                              </p>
                              <p>
                                However, if it is an urgent need, you should choose to hire someone
                                who is already familiar with your preferred tools.
                              </p>
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <ul className="list-unstyled list-shifted">
                            <li className="d-flex mb-4">
                              <div className="icon process-circle bg-primary-3 align-items-left">
                                5
                              </div>
                              <h2 className="mb-0 ">
                                What factors do you keep in mind while designing for varied types of
                                users?
                              </h2>
                            </li>
                            <li>
                              <p>
                                This is an important question of the entire&nbsp;UX designer hiring
                                process. Most User Experience designers answer this question with
                                demographic data.
                              </p>
                              <p>
                                However, an even more important factor here is triggers. What will
                                trigger your potential user to use or choose your products and
                                services? It is important that your product not only flaunts a
                                user-friendly user experience but also compels your users to take
                                the desired action.
                              </p>
                              <p>
                                While asking these questions is important, what is critical is to
                                understand that there is no best designer in this world. There is
                                only the BEST designer that suits your team well, fits any gap in
                                between, and enhances the overall user experience of your product,
                                thus helping your company move forward.
                              </p>
                              <p>
                                Also, the next important question that you should ask includes the
                                expected&nbsp;UX designer salary.
                              </p>
                              <p>Don&rsquo;t forget to ask for a&nbsp;UX design portfolio.</p>
                            </li>
                          </ul>
                        </article>
                      </div>
                    </div>
                  </div>
                  <hr className="my-0" />
                </div>

                <div className="bg-white">
                  <div className="container pb-0">
                    <div className="row justify-content-center">
                      <div className="col-xl-7 col-lg-8 col-md-10">
                        <article className="article lead">
                          <h2 className="h1">
                            Finally, a Few More Questions and You’re Good to Hire a UX Designer!
                          </h2>
                          <img
                            src={ImageEighthScreen}
                            alt="Navigating the complexity of change aversion"
                          />
                          <p>
                            Although the above-mentioned&nbsp;
                            <strong>interview questions for UX designer</strong>&nbsp;will provide
                            you with a brief overview of the candidate, here are a few additional
                            questions which will help you in making the right choice:
                          </p>
                          <ul>
                            <li>
                              Enquire them as to how do they keep themselves updated with the
                              industry trends? Do they attend any conferences?
                            </li>
                            <li>
                              Give them a test task to analyze their problem-solving skills,
                              designing skills, and communication.
                            </li>
                            <li>
                              The&nbsp;<strong>top&nbsp;</strong>
                              <strong>UX designers</strong>&nbsp;have a degree in fields like
                              graphic design, psychology, and human-computer interaction.
                            </li>
                            <li>
                              Do they come with any prior job experience? The size of the firm that
                              they have worked with doesn&rsquo;t play a vital role, but they must
                              come with hands-on experience of a few months, if not years!
                            </li>
                            <li>
                              Look at how diverse is their portfolio. Prefer them if they have
                              worked on a good&nbsp;<strong>mix of UX methods</strong>. If they have
                              taken care of various stages of UX design &ndash; that&rsquo;s an
                              added plus. Also, have they worked on a range of applications &ndash;
                              web applications, desktop programs, mobile applications, consumer
                              applications and the like &ndash; that&rsquo;s a bonus!
                            </li>
                          </ul>
                          <p>
                            Finding, evaluating, and hiring a UX designer is not the easiest task.
                            There&rsquo;s no denying that. But with the right approach and can-do
                            attitude, you can&nbsp;<strong>find and hire UX designers</strong>
                            &nbsp;to join your team.
                          </p>
                        </article>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          />
        </div>

        <SectionHireAgency />

        <section className="pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-8 d-flex flex-column text-center">
                <article
                  id="article-content"
                  className="article article-blog"
                  style={{ zIndex: 9993 }}
                >
                  <p>
                    <Helmet>  
                      <script type="application/ld+json">{`
                        {
                        "@context": "https://schema.org",
                        "@type": "FAQPage",
                        "mainEntity": [{
                          "@type": "Question",
                          "name": "Where Can You Find & Hire a UX Designer?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You can find & hire a ux designer using targeted outreach, online UI UX design communities or UX job boards."
                          }
                        },{
                          "@type": "Question",
                          "name": "How to get UX Services and hire the right provider?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "You can hire a freelance UX designer, work with an Agency or build your in-house team. Choosing among these three options is not an easy task. You should weigh each of these options based on their pros and cons."
                          }
                        },{
                          "@type": "Question",
                          "name": "How much does it cost to hire a UI UX designer",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "Freelancers could charge anywhere between $10 and $150 per hour. In-house designers
                            could make $50k to $175k. year. An agency might charge you around $10-19k. per full-time designer monthly. All
                            of these numbers depend on geography and other factors."
                          }
                        },{
                          "@type": "Question",
                          "name": "Why should I hire a UX designer?",
                          "acceptedAnswer": {
                            "@type": "Answer",
                            "text": "A UX designer translates your business goals into a highly usable design that converts, retains, and engages your customers."
                          }
                        }]
                        }
                      `}</script>
                    </Helmet>
                  </p>
                  <h3 className="text-left">FAQ</h3>
                  <div id="panel-1" className="card card-sm card-body card--no-hover">
                    <div className="accordion-panel-title" aria-controls="panel-1">
                      <p aria-controls="panel-1">
                        <strong aria-controls="panel-1">
                          {" "}
                          Where Can You Find &amp; Hire UX Designers?{" "}
                        </strong>
                      </p>
                      <img
                        className="icon"
                        src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                        aria-controls="panel-1"
                      />
                    </div>
                    <div className="accordion-content collapse">
                      <div className="pt-3">
                        <p>
                          You can find &amp; hire a ux designer using targeted outreach, online UI
                          UX design communities or UX job boards.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="panel-2" className="card card-sm card-body card--no-hover">
                    <div className="accordion-panel-title" aria-controls="panel-2">
                      <p aria-controls="panel-2">
                        <strong aria-controls="panel-2">
                          {" "}
                          How to get UX Services and hire the right provider?{" "}
                        </strong>
                      </p>
                      <img
                        className="icon"
                        src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                        aria-controls="panel-2"
                      />
                    </div>
                    <div className="accordion-content collapse">
                      <div className="pt-3">
                        <p>
                          You can hire a freelance UX designer, work with an Agency or build your
                          in-house team. Choosing among these three options is not an easy
                          task. You should weigh each of these options based on their pros and cons.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="panel-3" className="card card-sm card-body card--no-hover">
                    <div className="accordion-panel-title" aria-controls="panel-4">
                      <p aria-controls="panel-3">
                        <strong aria-controls="panel-3">
                          {" "}
                          How much does it cost to hire a UI UX designer?{" "}
                        </strong>
                      </p>
                      <img
                        className="icon"
                        src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                        aria-controls="panel-3"
                      />
                    </div>
                    <div className="accordion-content collapse">
                      <div className="pt-3">
                        <p>
                          Freelancers could charge anywhere between $10 and $150 per hour. In-house designers
                          could make $50k to $175k. year. An agency might charge you around $10-19k. per full-time designer monthly. All
                          of these numbers depend on geography and other factors.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="panel-4" className="card card-sm card-body card--no-hover">
                    <div className="accordion-panel-title" aria-controls="panel-4">
                      <p aria-controls="panel-4">
                        <strong aria-controls="panel-4">
                          {" "}
                          Why should I hire a UX designer?{" "}
                        </strong>
                      </p>
                      <img
                        className="icon"
                        src="https://www.datocms-assets.com/16499/1583766454-plus.svg"
                        aria-controls="panel-4"
                      />
                    </div>
                    <div className="accordion-content collapse">
                      <div className="pt-3">
                        <p>
                          A UX designer translates your business goals into a highly usable design that converts, retains, and engages your customers.
                        </p>
                      </div>
                    </div>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-primary-alt has-divider mt-4">
          <div className="divider">
            <Divider1 style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }} />
          </div>

          <div className="container">
            <div className="row mb-4">
              <div className="col">
                <h3 className="h2">Related Stories</h3>
              </div>
            </div>
            <div className="row">
              {ArticlesData.map(({ node }) => (
                <div className="col-md-6 col-lg-4" key={node.id}>
                  <CardBlog cardData={node} />
                </div>
              ))}
            </div>
          </div>
        </section>

        

      </div>
    </Layout>
  );
};


const SectionCaseStudies = () => (
  <section className="section-our-book pt-0 mt-n6">
    <div className="container pt-0">
      <div className="row bg-primary-alt rounded px-4 align-items-center section-our-book__wrapper">
        <div className="col-12 col-md-6 text-center section-our-book__image">
          <img
            src="https://www.datocms-assets.com/16499/1645654258-cta-exportable-ux-case-studies.png"
            alt="hire ux designer interview questions"
          />
        </div>
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
          <div>
            <span className="badge badge-primary-2">UX Case Studies</span>
            <h3 className="h1 my-2">See UX Design Agency Case Studies</h3>
            <p className="lead">
              Take a look at the case studies we’ve developed to see just what great UX can do.
            </p>
            <a
              href="https://adamfard.com/ux-projects"
              className="btn btn-primary btn-lg mt-3 py-2"
            >
              See Case Studies
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionHireAgency = () => (
  <section className="section-our-book text-light">
    <div className="container">
      <div className="row bg-primary-3 rounded px-4  align-items-center section-our-book__wrapper">
        <div className="col-12 col-md-6 order-md-last text-center section-our-book__image">
          <img
            src="https://www.datocms-assets.com/16499/1645654733-ux-team-adam-fard.png"
            alt="hire ux designer interview questions"
          />
        </div>
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
          <div>
            {/* <span className="badge badge-primary-2">World-class design agency</span> */}
            <h3 className="h1 my-2">Hire a data-driven UX Design Agency</h3>
            <p className="lead">
              Need help boosting your metrics? Let us show you how much difference a good UX can make.
            </p>
            <a
              href="https://adamfard.com/contact-us"
              className="btn btn-primary btn-lg mt-3 py-2"
              target="_blank"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const SectionOurBook = () => (
  <section className="section-our-book text-light">
    <div className="container">
      <div className="row bg-primary-3 rounded px-4  align-items-center section-our-book__wrapper">
        <div className="col-12 col-md-6 order-md-last text-center section-our-book__image">
          <img
            src="https://www.datocms-assets.com/16499/1579976839-ctauxdesignerinterviewquestions.png"
            alt="hire ux designer interview questions"
          />
        </div>
        <div className="col-12 col-md-6 d-flex flex-column justify-content-center">
          <div>
            <span className="badge badge-primary-2">Free Template</span>
            <h3 className="h1 my-2">Download UX Designer Interview Questions!</h3>
            <p className="lead">
              Here are 20 interview questions helping you to find the right candidate:
            </p>
            <a
              href="https://adamfard.com/download-ux-resources/ux-designer-interview-questions"
              className="btn btn-primary btn-lg mt-3 py-2"
            >
              Download Now
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const StickyPhaseHeader = ({ activePhase, scrollState }) => (
  <div className="sticky-wrapper" style={{ zIndex: 9991 }}>
    <div className="phase-reading-header layer-90">
      <div className="container py-0">
        <div className="phase-reading-header__wrapper">
          <div className="d-flex">
            {/* <small>Now reading: </small> <span>{activePhase}</span> */}
          </div>
          <div>
            <span>Hire a world class UX agency:</span>

            <a
              href="https://adamfard.com/contact-us"
              className="btn btn-primary ml-2"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className="ml-4 py-0">
      <div className="row mt-2">
        <div className="col phases-menu">
          <nav className="navbar-nav">
            <a
              href="#chapter-1"
              className={`my-1 pl-0 btn text-left ${
                scrollState.isFullyInViewport &&
                activePhase === "Phases / Section 1"
                  ? "active"
                  : ""
              }`}
            >
              <span>
                With whom to work:
                <br />
                Choose Wisely
              </span>
            </a>

            <a
              href="#chapter-2"
              className={`my-1 btn pl-0 text-left ${
                (scrollState.isFullyInViewport ||
                  scrollState.isBelowViewport) &&
                activePhase === "Phases / Section 2"
                  ? "active"
                  : ""
              }`}
            >
              <span>Find a Designer</span>
            </a>

            <a
              href="#chapter-3"
              className={`my-1 btn pl-0 text-left ${
                (scrollState.isFullyInViewport ||
                  scrollState.isBelowViewport) &&
                activePhase === "Phases / Section 3"
                  ? "active"
                  : ""
              }`}
            >
              <span>How to Evaluate</span>
            </a>

            <a
              href="#chapter-4"
              className={`my-1 btn pl-0 text-left ${
                (scrollState.isFullyInViewport ||
                  scrollState.isBelowViewport) &&
                activePhase === "Phases / Section 4"
                  ? "active"
                  : ""
              }`}
            >
              <span>Cost of hiring</span>
            </a>

            <a
              href="#chapter-5"
              className={`my-1 btn pl-0 text-left ${
                (scrollState.isFullyInViewport ||
                  scrollState.isBelowViewport) &&
                activePhase === "Phases / Section 5"
                  ? "active"
                  : ""
              }`}
            >
              <span>6 Mistakes to Avoid</span>
            </a>

            <a
              href="#chapter-6"
              className={`my-1 btn pl-0 text-left ${
                (scrollState.isFullyInViewport ||
                  scrollState.isBelowViewport) &&
                activePhase === "Phases / Section 6"
                  ? "active"
                  : ""
              }`}
            >
              <span>Interview Questions</span>
            </a>
          </nav>
        </div>
      </div>
    </div>

  {/*   <div
      className="sticky-top card-sm card card-body card--no-hover bg-primary-alt"
      style={{
        maxWidth: "261px",
        float: "right",
        marginRight: '50px'
      }}
    >
      <div className="text-center">
        <h4 className="h4 mb-2">Hire a UX designer</h4>
        <p className="">
          Let us help you get your product ahead of the curve through impeccable
          UX
        </p>
        <Link
          to="/contact-us"
          id="blog-c-freeconsultation"
          className="btn btn-primary mt-2"
          target="_blank"
        >
          Contact Us
        </Link>
      </div>
    </div> */}

  </div>
);

const WatchedPhaseSection = ({
  isDivider = false,
  bgColor = "bg-primary-alt",
  bgDivider2 = "bg-white",
  activePhase,
  phaseId,
  hidden = false,
  headerContent,
  mainContent,
}) => {
  const ref = useRef(null);
  const scrollState = useScrollState(ref);

  return (
    <section id={phaseId} className={isDivider ? bgColor + " " + "has-divider" : bgColor} ref={ref}>
      {isDivider && (
        <div className="divider">
          <Divider1 style={{ transform: "matrix(-1, 0, 0, -1, 0, 0)" }} />
        </div>
      )}
      {scrollState.isInViewport && !hidden && (
        <StickyPhaseHeader activePhase={activePhase} scrollState={scrollState} />
      )}

      <div className="header-content">{headerContent()}</div>

      {mainContent()}

      {/* {isDivider && (
        <div className={bgDivider2 + ' ' + "divider"}>
          <Divider1 style={{ transform: "matrix(1, 0, 0, 1, 0, 0)" }} />
        </div>
      )} */}
    </section>
  );
};

export default HireUXDesignerPage;

export const query = graphql`
  query HireUXDesignerPage {
    datoCmsPage(slug: { eq: "hire-ux-designer" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
    }

    allDatoCmsArticle(limit: 3, sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          cardDescription
          cardImage {
            url
          }
          category {
            name
          }
          id
          slug
        }
      }
    }
  }
`;
